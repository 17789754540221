@font-face {
  font-family: "Pirulen";
  src: url('./assets/pirulen.otf');
}

body {
  margin: 0;
  font-family: "Pirulen", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #fff;
  min-height: 100vh;
  height: 1px;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.connect-btn-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.connect-btn-container h1 {
  position: absolute;
  top: 20px;
  left: 53%;
  transform: translateX(-50%);
  font-size: 32px;
  color: #fff;
  z-index: 20;
  text-align: center;
  text-transform: uppercase;
  margin: 0;
}

.video-container {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.connect-wallet {
  position: absolute;
  top: 60%;
  left: 52%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  background: #63c702;
  height: 100px;
  min-width: 425px;
  color: #fff;
  appearance: none;
  -webkit-appearance: none;
  font-size: 36px;
  border: 4px solid #63c702;
  border-radius: 30px !important;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: color 0.7s ease, box-shadow 0.5s ease;
  z-index: 250;
  font-family: "Pirulen";
}

.mint-btn {
  display: flex;
  align-items: center;
  background: #63c702;
  height: 60px;
  min-width: 200px;
  color: #fff;
  appearance: none;
  -webkit-appearance: none;
  font-size: 28px;
  border: 4px solid #63c702;
  border-radius: 30px !important;
  text-align: center;
  justify-content: center;
  cursor: pointer;
  text-transform: uppercase;
  transition: color 0.7s ease, box-shadow 0.5s ease;
}

.connect-wallet:hover,
.mint-btn:hover {
  box-shadow: inset 0px -100px 0 0px #fff;
  color: #000;
}

/* iframe {
  display: none;
} */

.copyright {
  position: absolute;
  bottom: 0;
  left: 20px;
  color: #fff;
  font-size: 26px;
  text-transform: uppercase;
  z-index: 20;
  margin: 0;
}

.minters {
  position: absolute;
  top: 60%;
  left: 52%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  gap: 25px;
}

.socials-container {
  position: absolute;
  display: flex;
  align-items: center;
  gap: 20px;
  right: 20px;
  bottom: 20px;
}

.social--item {
  width: 45px;
  height: 45px;
  text-align: center;
  line-height: 45px;
  font-size: 28px;
  color: #fff;
  background-color: #63c702;
  border-radius: 50%;
}

iframe {
  display: none;
}

.video-container iframe {
  display: block;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 567px) {
  .connect-wallet {
    min-width: 300px;
    height: 50px;
    font-size: 22px;
    z-index: 500;
  }
  .video-container {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100vh;
  }

  .minters {
    flex-direction: column;
  }
  .socials-container {
    width: 100%;
    justify-content: center;
    bottom: 40px;
    right: 0;
    z-index: 5000;
  }
  .copyright {
    left: 0;
    width: 100%;
    text-align: center;
  }
  .connect-btn-container h1 {
    width: 100%;
    top: 5%;
    left: 0;
    text-align: center;
    transform: none;
    font-size: 24px;
    font-weight: bold;
    z-index: 500;
  }
}
